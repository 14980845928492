<template>
  <div>
    <main class="page-content">
      <div class="account-page-area">
      <div class="">
          <div class="row">
            <div class="col-lg-3 account_border-right">
              <SideMenu></SideMenu>
            </div>
            <div class="col-lg-9">
              <h3>My Winnings</h3>
              <div v-if="!items.length">
                <p><NotFound></NotFound></p>
                <!-- <loader></loader> -->
              </div>
              <div v-else>
                <section>
                  <table>
                    <thead>
                      <tr>
                        <th scope="col">Auction Name</th>
                        <th scope="col">Bid Amount</th>
                        <th scope="col">Model Name</th>
                        <!-- <th scope="col">Token Amount</th>
                        <th scope="col"></th> -->
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="list in items" :key="list.acuId">
                        <th>{{ list.aucName }}</th>
                        <td>$ {{ list.latestBidAmount }}</td>
                        <td>{{ list.modelName }}</td>
                        <!-- <td>$ {{ list.tokenAmount }}</td>
                        <td>
                          <button @click="payTokenAmount()" class="btn-invoice">
                            Pay
                          </button>
                        </td> -->
                      </tr>
                    </tbody>
                  </table>
                </section>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="uren-paginatoin-area">
                <div class="row">
                  <div class="col-lg-12">
                    <PageNation
                      :vechiclePage="AuctionsDetails"
                      @pageChange="PageRout"
                    ></PageNation>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import NotFound from "@/components/modules/NotFound.vue";
import PageNation from "@/components/modules/PageNation.vue";
import userService from "@/services/user.service";
// import VehicleCard from "../modules/VehicleCard.vue";
import Config from "@/config.js";
import SideMenu from "@/components/sideMenu.vue";
export default {
  name: "Winnings",
  components: {
    SideMenu,
    PageNation,
    NotFound,
  },
  data() {
    return {
      path: Config.BASE_URL_IMG,
      openModel: false,
      amount: "",
      items: [],
      userinfo: "",
      custId: "",
      bidAmount: "",
      Buttondisabled: false,
      selectedAuction: "",
      message: "",
      perPage: 2,
      currentPage: 1,
      AuctionsDetails: [],
    };
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("user"));
    if (this.userinfo) {
      if (typeof this.userinfo.customerId != "undefined") {
        this.custId = this.userinfo.customerId;
      } else {
        console.log("vendor");
      }
    }
    this.getCustomerWinnings();
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    PageRout(userData) {
      // console.log(userData);
      this.items = userData;
    },

    getCustomerWinnings() {
      userService
        .getCustomerWinnings(this.custId)
        .then((response) => {
          this.AuctionsDetails = response.data.Data;
          this.items = this.AuctionsDetails.slice(
            (this.currentPage - 1) * this.perPage,
            this.currentPage * this.perPage
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {},
};
</script>
<style scoped>
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* body {
  font-family: "Nunito", sans-serif;
  color: #384047;
} */

table {
  width: 100%;
  margin: 15px auto;
}

caption {
  font-size: 1.6em;
  font-weight: 400;
  padding: 10px 0;
}

thead th {
  font-weight: 400;
  background: #002868;
  color: #fff;
}

tr {
  background: #f4f7f8;
  border-bottom: 1px solid #fff;
  margin-bottom: 5px;
}

tr:nth-child(even) {
  background: #e8eeef;
}

th,
td {
  text-align: left;
  padding: 10px;
  font-weight: 300;
}

tfoot tr {
  background: none;
}

tfoot td {
  padding: 10px 2px;
  font-size: 0.8em;
  font-style: italic;
  color: #8a97a0;
}
</style>
